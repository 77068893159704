.custom-card {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); /* Sombra leve */
    border: 1px solid rgba(0, 0, 0, 0.1); /* Borda sutil */
    border-radius: 0.375rem; /* Igual ao padrão Tailwind (6px) */
  }

button {
  align-items: center;
  white-space: normal; /* Permite quebra de linha no texto */
  overflow: hidden;    /* Garante que o texto que extrapolar será escondido */
  text-overflow: ellipsis; /* Adiciona "..." caso o texto ultrapasse o limite */
}

.input-like-button {
  border: 2px solid #d9d9d9;
  border-radius: 0.25rem;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 12px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr-code {
  max-width: 100%;        /* Ajusta a largura máxima para o tamanho disponível */
  max-height: 300px;      /* Ajuste o valor de max-height conforme necessário */
  width: auto;            /* Mantém a proporção da imagem */
  height: auto;           /* Mantém a proporção da imagem */
  display: block;         /* Remove qualquer espaço abaixo da imagem */
  margin: 0 auto;         /* Centraliza a imagem */
}

.modal-footer {
  display: flex;
  justify-content: center; /* Centraliza os botões */
  width: 100%;              /* Garante que o footer ocupe toda a largura */
  gap: 10px;                /* Adiciona um espaço entre os botões */
}


@media only screen and (max-width: 800px) {
  button.custom-btn {
    align-items: center;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.2rem; /* Reduz o tamanho da fonte */
  }
  .custom-card{
    align-items: center;
  }
}