.Main_container {
  height: 100%;
  width: 100%;
  display: flex;
}

button {
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
}

.Main_sidebar {
  box-shadow: 1px 0px 24px rgba(0, 0, 0, 0.1);
  height: 100%;
  width: 80px;
  padding: 20px 0;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 20px 0 0 20px; /* Bordas arredondadas na sidebar */
  background-color: #ffffff; /* Cor de fundo mais clara */
  transition: all 0.3s ease; /* Transição suave para a abertura/fechamento */
}

.Main_sidebar.open {
  width: 260px;
  border-radius: 20px; /* Mantém a borda arredondada quando aberta */
}

.sidebar-burger {
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
  background-color: #5a6acf; /* Cor destacada */
  border: none;
  border-radius: 50%; /* Botão arredondado */
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra para destaque */
  transition: transform 0.2s ease, background-color 0.3s ease;

  /* Posicionamento */
  position: absolute;
  top: 50%; /* Centraliza verticalmente */
  transform: translate(50%, -50%); /* Ajusta posição para fora */
  right: -15px; /* Metade para fora */
}

.sidebar-burger.open {
  right: -200px; /* Ajuste conforme necessário para acompanhar o aumento da sidebar */
}

.sidebar-burger:hover {
  /* transform: scale(1.1); */
  background-color: #3f4ca6; /* Cor de hover */
}

.icon {
  color: white; /* Ícones brancos no botão */
}

.Main_menuitemLink {
  color: #000000;
  margin-top: 50px;
  display: none;
}

.Main_menuitemLink.open {
  color: #000000;
  margin-top: 20px;
  display: flex;
}

.Main_menuitemLinkSecond {
  color: #000000;
  margin-top: 50px;
  display: none;
}

.Main_menuitemLinkSecond.open {
  color: #000000;
  margin-top: 20px;
  display: flex;
}

.Main_menuitemLinkActive {
}

.Main_menuitemLinkActive .Main_menuitem {
  border-left: 5px solid #5a6acf;
}

.Main_menuitem {
  border-radius: 10px; /* Botões arredondados na lista */
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-left: 5px solid transparent;
}

.Main_menuitem img {
  width: 30px;
}

.Main_menuitem > div {
  padding-left: 5px;
}

.Main_right {
  flex: 1;
  /* margin-top: 10px; */
  margin-left: 30px;
}
.Main_header {
  display: none;
}

.Main_header.open {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px !important;
  /* margin-top: 700px; */
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: center;
}

.Main_header_title {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
}

.Main_content {
  height: calc(100vh - 80px);
  overflow-y: auto;
  /* margin-right: 100px; */
}

.Main_menuProfile {
  position: relative; /* Necessário para o posicionamento absoluto do botão */
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  margin-top: 10px;
  margin-left: 3px;
}

.Main_menuitem:hover {
  background-color: #f2f2f2; /* Fundo mais claro ao passar o mouse */
}

.Main_menuProfile img {
  height: 50px;
}

.Main_dropdownIcon {
  width: 18px;
  height: auto !important;
}

.Main_header_title {
  display: none;
}
.Main_headerBetween {
}

.Main_headerRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Main_headerNoti {
  margin: 0 20px;
  cursor: pointer;
}

.Main_headerNoti img {
  height: 22px;
}
.Main_headerSearch {
  margin: 0 20px;
  cursor: pointer;
}
.Main_headerSearch img {
  height: 22px;
}

.SidebarMaquina {
  display: none;
}

.SidebarMaquina.open {
  display: flex;
}

@media only screen and (max-width: 850px) {

  .Main_right{
    margin-left: 10px;
  }

  .Main_container {
    display: flex;
  }

  .Main_content {
    height: calc(100vh - 80px);
    overflow-y: auto;
    margin-left: 10px;
    /* margin-right: 100px; */
  }
}

@media only screen and (max-width: 850px) and (orientation: landscape) {
  
}

.icon {
  /* color: #4169E1; */
  color: #5a6acf;
}

.circle-icon {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  width: 50px; /* Largura do círculo */
  height: 50px; /* Altura do círculo */
  border-radius: 50%; /* Para criar um círculo */
  background-color: #f1f2f7; /* Cor do círculo */
  color: rgb(89, 105, 207); /* Cor do texto dentro do círculo */
  font-size: 14px; /* Tamanho do texto */
}
