* {
  box-sizing: border-box;
}

.sidebar {
  --width-open: 260px;
  --width-closed: 64px;
}

body {
  margin: 0;
  /* background: #6669bb; */
  /* color: #7a7b92; */
  font-family: "Euclid Circular A", "Poppins";
}

.sidebar button {
  background: transparent;
  outline: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  font-family: inherit;
}

.sidebar {
  position:relative;
  border: 1px solid rgba(0, 0, 0, .125);
  top: 5px;
  left: 10px;
  bottom: 30px;
  width: var(--width-closed);
  height: 98%;
  border-radius: 16px;
  /* background: #1e1b29; */
  transition: width 0.3s;
}

.sidebar.open {
  width: var(--width-open);
}

button.toggleButton{
  margin-left: 2px;
  position: absolute;
  display: grid;
  place-items: center;
  padding: 0;
  width: 60px;
  height: 60px;
  /* background: #007bff; */
  color: #f9f9f9;
  /* box-shadow: 0 0 10px #8f8d9b; */
}

button.toggle.open {
  position: absolute;
  z-index: 2;
  top: 24px;
  right: -12px;
  display: grid;
  place-items: center;
  padding: 0;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #007bff;
  color: #f9f9f9;
  box-shadow: 0 0 10px #8f8d9b;
  transition: 0.3s;
}

.toggle:hover {
  color: #f9f9f9;
}

.sidebar.open .toggle {
  rotate: 180deg;
}

.sidebar .search {
  position: relative;
  padding: 16px 0;
  margin: 0 10px 0;
  width: calc(var(--width-closed) - 20px);
  transition: 0.3s;
}

.sidebar.open .search {
  width: calc(var(--width-open) - 20px);
}

.sidebar .search span {
  position: absolute;
  top: 26px;
  left: 12px;
  color: #68687a;
}

.sidebar .search input {
  background: #2f2b43;
  border: 0;
  border-radius: 8px;
  font-family: inherit;
  width: 100%;
  height: 44px;
  font-size: 17px;
  padding-left: 30px;
  transition: 0.3s;
}

.sidebar .search input::placeholder {
  color: rgb(255 255 255 / 0%);
}

.sidebar.open .search input::placeholder {
  color: #68687a;
}

.sidebar.open .search input {
  padding-left: 52px;
}

.sidebar .inner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  /* width: 260px; */
}

.sidebar .header {
  display: flex;
  align-items: center;
  /* text-align: center; */
  height: 72px;
  width: var(--width-closed);
  /* padding: 0 20px; */
  background: #007bff;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  transition: 0.3s;
}

.header-title{
  display: none;
}

.header-title.open {
  display: flex;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  color: #000000;
  margin: auto; /* Alinhar automaticamente dentro da div */
}

.sidebar.open .header {
  width: var(--width-open);
}

.sidebar .header h1 {
  margin-left: 12px;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 2px;
  color: #f9f9f9;
}

.sidebar .logo {
  height: 28px;
}

.sidebar .menu{
  position: relative;
  display: grid;
  span{
    margin-left: 5px;
    white-space: nowrap;
  }
}

.sidebar .menu::after {
  content: "";
  position: absolute;
  top: 0;
  left: 4px;
  height: 56px;
  width: 4px;
  /* background: #007bff; */
  translate: 0 var(--top);
  transition: 0.3s;
}

.sidebar .menu button {
  display: flex;
  gap: 16px;
  align-items: center;
  height: 56px;
  width: var(--width-closed);
  font-size: 17px;
  text-transform: capitalize;
  line-height: 1;
  padding: 0 22px;
  color: inherit;
  cursor: pointer;
  opacity: 0.8;
  transition: 0.3s;
}

.sidebar.open .menu button {
  width: var(--width-open);
}

.sidebar .menu :is(button:hover, .active) {
  color: #007bff;
  border-left:2px solid #007bff;
  opacity: 1;
}

.sidebar.open .menu .active {
  cursor: default;
}

.sidebar .menu button:hover > span {
  opacity: 1;
}

.sidebar .menu button p,
.sidebar .header h1 {
  opacity: 0;
}

.sidebar.open :is(.sidebar .menu button p, .sidebar .header h1) {
  opacity: 1;
}

.sidebar.open .logo {
  scale: 1;
}

.sidebar .menu button > img {
  width: 24px;
  height: 24px;
}

.large-icon {
  font-size: 24px; /* Ajuste o tamanho conforme necessário */
}


.user-card {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px;
  margin-left: 15px;
}

.user-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-name {
  display: none;
}

.user-name.open {
  display: flex;
  font-weight: 400;
}

.logout-button {
  /* Style the logout button as needed */
  background-color: #f0f0f0;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  text-align: center;
  width: 100%;
}