/* CustomDateRangePicker.css */

/* Personalizando a cor do placeholder */

  .ant-picker-input input::placeholder {
    color: #007bff; /* Cor personalizada para o placeholder */
    font-weight: 400;
    padding: 10px;
    font-size: 1rem;
  }
  
  /* Personalizando a cor do ícone do calendário */
  .ant-picker-suffix {
    color: #007bff; /* Altere para a cor desejada */
  }
  
  /* Personalizando o estilo do RangePicker */
  .ant-picker {
    color: #007bff;
    font-weight: 400;
    width: 100%; /* Faz o RangePicker ocupar 100% da largura */
    border: 1px solid #007bff; /* Borda personalizada */
    border-radius: 4px; /* Bordas arredondadas */
    padding: 10px; /* Ajusta o padding para ter a mesma altura do botão */
    margin-bottom: 8px;
  }

  @media only screen and (max-width: 800px) {
    .ant-picker-input input::placeholder {
      color: #007bff; /* Cor personalizada para o placeholder */
      font-weight: 400;
      padding: 10px;
      font-size: 1.2rem;
    }
  }
  